import React, { useState, useEffect, useRef } from "react";

import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Tooltip, CheckboxGroup, Checkbox, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Link, Chip, RadioGroup, Radio} from "@nextui-org/react";

import { useAuth } from "../context/auth_context";
import { useAlert } from "../context/alert_context";

import { apiEndpointInfo } from "../constants/api_endpoint_info";
import { subscriptionPricing } from "../constants/api_endpoint_info";

export function SubscriptionPackages() {   
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        requestTrialAccount,
        requestTrialAccountLoading,
        handleSubscriptionPurchase,
        subscriptionPurchaseLoading,
    } = useAuth()
    const { showToast } = useAlert();

    const [featureSelected, setFeatureSelected] = useState(["one", "two", "three"]);

    const [subscriptionState, setSubscriptionState] = useState({
        selectedRoutes: [],
        routePricing: {},
        groupedRoutes: {},
        paymentType: "usdc"
    });

    // function calculateSubscriptionPricing(selectedRoutes) {
    //     if (selectedRoutes.length === 0) {
    //         setSubscriptionState(prevState => ({
    //             ...prevState,
    //             routePricing: {} // Reset pricing if no routes are selected
    //         }));
    //         return;
    //     }

    //     const base_monthly = 1500;
    //     const pep_monthly = 80;
    //     const base_biannual = 8000;
    //     const pep_biannual = 384;
    //     const base_annual = 15000;
    //     const pep_annual = 650;
        
    //     let calcs = {
    //         get_follower_count: selectedRoutes.includes("get_follower_count") ? 2 : 0,
    //         get_latest_metadata: selectedRoutes.includes("get_latest_metadata") ? 1 : 0,
    //         get_metadata_history: selectedRoutes.includes("get_metadata_history") ? 2 : 0,
    //         get_past_usernames: selectedRoutes.includes("get_past_usernames") ? 3 : 0,
    //         get_userid: selectedRoutes.includes("get_userid") ? 0 : 0,
    //         get_followers: selectedRoutes.includes("get_followers") ? 3 : 0,
    //         get_following: selectedRoutes.includes("get_following") ? 3 : 0,
    //         get_classification: selectedRoutes.includes("get_classification") ? 1 : 0,
    //         get_score_history: selectedRoutes.includes("get_score_history") ? 2 : 0
    //     };
    
    //     const total_creds = Object.values(calcs).reduce((a, b) => a + b, 0);
        
    //     if (total_creds === 0) {
    //         return [0, 0, 0, 0, 0];
    //     }
    
    //     const nonzero_creds = Object.values(calcs).filter(x => x !== 0);
    //     let monthly_cost, biannual_cost, annual_cost, biannual_discount, annual_discount;
    
    //     if (nonzero_creds.length === 1 && nonzero_creds[0] !== 0) {
    //         monthly_cost = base_monthly;
    //         const annual_cost_by_month = monthly_cost * 12;
    //         biannual_cost = base_biannual;
    //         const annual_cost_by_biannual = biannual_cost * 2;
    //         annual_cost = base_annual;
    //         biannual_discount = Math.round(((annual_cost_by_month - annual_cost_by_biannual) / annual_cost_by_month) * 100 * 10) / 10;
    //         annual_discount = Math.round(((annual_cost_by_month - annual_cost) / annual_cost_by_month) * 100 * 10) / 10;
    //     } else {
    //         monthly_cost = (total_creds - 1) * pep_monthly + base_monthly;
    //         const annual_cost_by_month = monthly_cost * 12;
    //         biannual_cost = (total_creds - 1) * pep_biannual + base_biannual;
    //         const annual_cost_by_biannual = biannual_cost * 2;
    //         annual_cost = (total_creds - 1) * pep_annual + base_annual;
    //         biannual_discount = Math.round(((annual_cost_by_month - annual_cost_by_biannual) / annual_cost_by_month) * 100 * 10) / 10;
    //         annual_discount = Math.round(((annual_cost_by_month - annual_cost) / annual_cost_by_month) * 100 * 10) / 10;
    //     }
        
    //     // return [monthly_cost, biannual_cost, annual_cost, biannual_discount, annual_discount];
    //     // return {monthly_cost, biannual_cost, annual_cost, biannual_discount, annual_discount};
    //     const pricing = {monthly_cost, biannual_cost, annual_cost, biannual_discount, annual_discount};

    //     setSubscriptionState(prevState => ({
    //         ...prevState,
    //         routePricing: pricing
    //     }));
    // }

    function calculateSubscriptionPricing(selectedRoutes) {
        if (selectedRoutes.length === 0) {
            setSubscriptionState(prevState => ({
                ...prevState,
                routePricing: {}
            }));
            return;
        }
    
        const base_monthly = 1500;
        const pep_monthly = 80;
        const base_quarterly = 4250;
        const pep_quarterly = 220;
        const base_biannual = 8000;
        const pep_biannual = 384;
        const base_annual = 15000;
        const pep_annual = 650;
    
        let calcs = {
            get_follower_count: selectedRoutes.includes("get_follower_count") ? 2 : 0,
            get_latest_metadata: selectedRoutes.includes("get_latest_metadata") ? 1 : 0,
            get_latest_tweets: selectedRoutes.includes("get_latest_tweets") ? 6 : 0,
            get_metadata_history: selectedRoutes.includes("get_metadata_history") ? 2 : 0,
            get_past_usernames: selectedRoutes.includes("get_past_usernames") ? 3 : 0,
            get_userid: selectedRoutes.includes("get_userid") ? 0 : 0,
            get_followers: selectedRoutes.includes("get_followers") ? 3 : 0,
            get_following: selectedRoutes.includes("get_following") ? 3 : 0,
            get_classification: selectedRoutes.includes("get_classification") ? 1 : 0,
            get_score_history: selectedRoutes.includes("get_score_history") ? 2 : 0
        };
    
        const total_creds = Object.values(calcs).reduce((a, b) => a + b, 0);
    
        if (total_creds === 0) {
            return [0, 0, 0, 0, 0];
        }
    
        const nonzero_creds = Object.values(calcs).filter(x => x !== 0);
        let monthly_cost, quarterly_cost, biannual_cost, annual_cost, quarterly_discount, biannual_discount, annual_discount;

        if (nonzero_creds.length === 1 && nonzero_creds[0] !== 0) {
            monthly_cost = base_monthly;
            quarterly_cost = base_quarterly;
            biannual_cost = base_biannual;
            const annual_cost_by_month = monthly_cost * 12;
            const annual_cost_by_quarterly=quarterly_cost * 4;
            const annual_cost_by_biannual = biannual_cost * 2;
            annual_cost = base_annual;
            quarterly_discount = Math.round(((annual_cost_by_month - annual_cost_by_quarterly) / annual_cost_by_month) * 100 * 10) / 10;
            biannual_discount = Math.round(((annual_cost_by_month - annual_cost_by_biannual) / annual_cost_by_month) * 100 * 10) / 10;
            annual_discount = Math.round(((annual_cost_by_month - annual_cost) / annual_cost_by_month) * 100 * 10) / 10;
        } else {
            monthly_cost = (total_creds - 1) * pep_monthly + base_monthly;
            quarterly_cost = (total_creds - 1) * pep_quarterly + base_quarterly;
            biannual_cost = (total_creds - 1) * pep_biannual + base_biannual;
            const annual_cost_by_month = monthly_cost * 12;
            const annual_cost_by_quarterly=quarterly_cost * 4;
            const annual_cost_by_biannual = biannual_cost * 2;
            annual_cost = (total_creds - 1) * pep_annual + base_annual;
            quarterly_discount = Math.round(((annual_cost_by_month - annual_cost_by_quarterly) / annual_cost_by_month) * 100 * 10) / 10;
            biannual_discount = Math.round(((annual_cost_by_month - annual_cost_by_biannual) / annual_cost_by_month) * 100 * 10) / 10;
            annual_discount = Math.round(((annual_cost_by_month - annual_cost) / annual_cost_by_month) * 100 * 10) / 10;
        }
    
        const pricing = { monthly_cost, quarterly_cost, biannual_cost, annual_cost, quarterly_discount, biannual_discount, annual_discount };
    
        setSubscriptionState(prevState => ({
            ...prevState,
            routePricing: pricing
        }));
    }       

    useEffect(() => {
        console.log(subscriptionState.selectedRoutes);
        calculateSubscriptionPricing(subscriptionState.selectedRoutes);
    }, [subscriptionState.selectedRoutes]);

    const reset = () => {
        setSubscriptionState(prevState => ({
            ...prevState,
            selectedRoutes: [],
            routePricing: {}
        }));
    }

    const selectAllRoutes = () => {
        const allRoutes = apiEndpointInfo.reduce((acc, endpoint) => {
            const routes = endpoint.routes
                .filter(route => !route.disabled)
                .map(route => route.name);
            return acc.concat(routes);
        }, []);
        setSubscriptionState(prevState => ({
            ...prevState,
            selectedRoutes: allRoutes
        }));
    }

    // const handleBuyClick = (subscriptionLength) => {
    //     const selectedPackagePricing = {};
    //     if (subscriptionLength === '1') {
    //         selectedPackagePricing.price = subscriptionState.routePricing.monthly_cost;
    //     } else if (subscriptionLength === '6') {
    //         selectedPackagePricing.price = subscriptionState.routePricing.biannual_cost;
    //     } else if (subscriptionLength === '12') {
    //         selectedPackagePricing.price = subscriptionState.routePricing.annual_cost;
    //     }
    //     const updatedItem = {
    //         grouped_routes: subscriptionState.groupedRoutes,
    //         payment_type: subscriptionState.paymentType,
    //         route_pricing: selectedPackagePricing,
    //         selected_package_length: subscriptionLength,
    //         package_type: 'subscription'
    //     };

    //     console.log('SUBSCRIPTION STATE', subscriptionLength);
    //     console.log('UPDATED STATE TO SEND:', updatedItem);

    //     handleSubscriptionPurchase(updatedItem)
    // };

    const handleBuyClick = (subscriptionLength) => {
        const selectedPackagePricing = {};
        if (subscriptionLength === '1') {
            selectedPackagePricing.price = subscriptionState.routePricing.monthly_cost;
        } else if (subscriptionLength === '3') {
            selectedPackagePricing.price = subscriptionState.routePricing.quarterly_cost;
        } else if (subscriptionLength === '6') {
            selectedPackagePricing.price = subscriptionState.routePricing.biannual_cost;
        } else if (subscriptionLength === '12') {
            selectedPackagePricing.price = subscriptionState.routePricing.annual_cost;
        }
        const updatedItem = {
            grouped_routes: subscriptionState.groupedRoutes,
            payment_type: subscriptionState.paymentType,
            route_pricing: selectedPackagePricing,
            selected_package_length: subscriptionLength,
            package_type: 'subscription'
        };
    
        console.log('SUBSCRIPTION STATE', subscriptionLength);
        console.log('UPDATED STATE TO SEND:', updatedItem);
    
        handleSubscriptionPurchase(updatedItem)
    };    

    useEffect(() => {
        const groupedRoutes = subscriptionState.selectedRoutes.reduce((acc, route) => {
            const namespace = apiEndpointInfo.find(endpoint => 
                endpoint.routes.some(r => r.name === route)
            )?.endpoint;

            if (namespace) {
                if (!acc[namespace]) {
                    acc[namespace] = [];
                }
                acc[namespace].push(route);
            }
            return acc;
        }, {});

        setSubscriptionState(prevState => ({
            ...prevState,
            groupedRoutes: groupedRoutes
        }));
    }, [subscriptionState.selectedRoutes]);

    // useEffect(() => {
    //     console.log('payment type selected', subscriptionState.paymentType);
    // }, [subscriptionState.paymentType]);
    
    return (
        <>
            <p className="text-5xl">Subscription Packages</p>
            <p className="text-sm text-default-500">Starting at $1,500 for one endpoint for one month</p>

            <CheckboxGroup
                label="Features"
                // defaultValue={["one", "two", "three"]}
                value={featureSelected}
                onValueChange={setFeatureSelected}
                className="mt-10"
                // orientation="horizontal"
            >
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="one">Unlimited requests</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="two">Bulk discount pricing</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="three">Quick support response time</Checkbox>
            </CheckboxGroup>

            <div className="mt-10">
                <RadioGroup
                    label="Payment Method"
                    value={subscriptionState.paymentType}
                    onValueChange={(newPaymentType) => setSubscriptionState(prevState => ({ ...prevState, paymentType: newPaymentType }))}
                    orientation="horizontal"
                    size="sm"
                    color="success"
                >
                    {/* <Radio value="ethereum">Ethereum</Radio> */}
                    <Radio value="usdc">USDC</Radio>
                    <Radio value="usdt">USDT</Radio>
                </RadioGroup>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-10 h-auto sm:h-full">
                <div className="flex flex-col">
                    <Card className="h-full">
                        <CardHeader>
                            <p className="text-default-500">Select Routes</p>
                        </CardHeader>
                        <CardBody>
                            <CheckboxGroup
                                color="warning"
                                value={subscriptionState.selectedRoutes}
                                onValueChange={(newRoutes) => setSubscriptionState(prevState => ({ ...prevState, selectedRoutes: newRoutes }))}
                            >
                                {apiEndpointInfo.map(endpoint => (
                                    <div key={endpoint.endpoint} className="mb-4 last:mb-0">
                                        <p>{endpoint.endpoint.toUpperCase()}</p>
                                        <p className="text-xs text-default-500">{endpoint.description}</p>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 mt-2">
                                            {endpoint.routes.map(route => (
                                                <Checkbox
                                                    key={route.name}
                                                    color="success"
                                                    value={route.name}
                                                    isDisabled={route.disabled}
                                                >
                                                    {/* <span className="text-xs">
                                                        {route.name} {route.free ? <span className="text-lg">🆓</span> : ""} {route.popular ? <span className="text-lg">🔥🔥🔥</span> : ""}
                                                    </span> */}
                                                    <span className={route.disabled ? "text-xs line-through" : "text-xs"}>
                                                        {route.name} 
                                                        {route.free ? <span className="text-lg">🆓</span> : ""} 
                                                        {route.popular ? <span className="text-lg">🔥🔥🔥</span> : ""}
                                                    </span>
                                                </Checkbox>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </CheckboxGroup>
                        </CardBody>
                        <CardFooter className="space-x-2">
                            <Button size="sm" onClick={selectAllRoutes}>Select All</Button>
                            <Button size="sm" onClick={reset}>Reset</Button>
                        </CardFooter>
                    </Card>
                </div>

                <div className="flex flex-col space-y-4 h-full">
                    {subscriptionPricing.map((item, index) => {
                        let priceDisplay = '';
                        let discountDisplay = '';

                        // Price and discount logic
                        if (item.subscription === "1" && subscriptionState.routePricing.monthly_cost) {
                            priceDisplay = `$${subscriptionState.routePricing.monthly_cost.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                        }
                        if (item.subscription === "3" && subscriptionState.routePricing.quarterly_cost) {
                            priceDisplay = `$${subscriptionState.routePricing.quarterly_cost.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                            discountDisplay = subscriptionState.routePricing.quarterly_discount ? <span className="text-sm text-green-500"> ({subscriptionState.routePricing.quarterly_discount}% off)</span> : '';
                        }
                        if (item.subscription === "6" && subscriptionState.routePricing.biannual_cost) {
                            priceDisplay = `$${subscriptionState.routePricing.biannual_cost.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                            discountDisplay = subscriptionState.routePricing.biannual_discount ? <span className="text-sm text-green-500"> ({subscriptionState.routePricing.biannual_discount}% off)</span> : '';
                        }
                        if (item.subscription === "12" && subscriptionState.routePricing.annual_cost) {
                            priceDisplay = `$${subscriptionState.routePricing.annual_cost.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                            discountDisplay = subscriptionState.routePricing.annual_discount ? <span className="text-sm text-green-500"> ({subscriptionState.routePricing.annual_discount}% off)</span> : '';
                        }

                        return (
                            <Card 
                                shadow="sm" 
                                key={index} 
                                // isPressable 
                                // onClick={onOpen}
                                // isFooterBlurred
                                className="h-[140px] sm:flex-grow"
                                style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <CardHeader className="flex flex-col items-start absolute">
                                    <h4 className="text-white font-medium text-5xl">{item.subscription_title}</h4>
                                    {priceDisplay === '' ? (
                                        <p className="text-default-500 text-xs">
                                            Select a route to display pricing
                                        </p>
                                    ) : (
                                        <p className="text-default-500 text-2xl">
                                            {priceDisplay} {discountDisplay}
                                        </p>
                                    )}
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                                <CardFooter>
                                    {signedIn ? (
                                        <Button 
                                            size="sm" 
                                            onClick={() => handleBuyClick(item.subscription)}
                                            isDisabled={
                                                subscriptionState.selectedRoutes.length === 0 ||
                                                (!subscriptionState.routePricing.monthly_cost &&
                                                !subscriptionState.routePricing.biannual_cost &&
                                                !subscriptionState.routePricing.annual_cost) || 
                                                subscriptionPurchaseLoading
                                            }
                                            isLoading={subscriptionPurchaseLoading}
                                        >
                                            {subscriptionPurchaseLoading ? null : (
                                                subscriptionState.selectedRoutes.length === 0 ||
                                                (!subscriptionState.routePricing.monthly_cost &&
                                                !subscriptionState.routePricing.biannual_cost &&
                                                !subscriptionState.routePricing.annual_cost)
                                                ? "Select an Endpoint"
                                                : "Purchase"
                                            )}
                                        </Button>
                                    ) : (
                                        <Button 
                                            as={Link}
                                            href="/login"
                                            size="sm"
                                        >
                                            Login
                                        </Button>
                                    )}
                                </CardFooter>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </>
    );
}